<template>
  <div class="terms">
    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

    <div class="row ">
      <div class="col-md-12">
        <div class="text-center mt-3 mb-3">
          <h6>Kindly help us with the following details before you proceed</h6>
        </div>
        <div class=" p-1 mb-5  rounded">

          <form-wizard ref="personalDetails" stepSize="sm" title="" subtitle="" finishButtonText="Confirm" action="#">
            <tab-content title="Personal details" :before-change="updatePersonalDetails"
                         icon="ti-user" :selected="true" >

              <div class=" shadow-lg bg-white">


                <div class="p-3">
                  <h6 style="margin-bottom: 10px;">Personal Details: </h6>
                  <div class="row">
                    <div class="col-12">
                      <label :class="{ 'text-danger': $v.coverDetails.fullName.$error }" >Full Names: <sup>*</sup>  </label> <br/>
                      <input v-model.trim="$v.coverDetails.fullName.$model" type="text" class="form-control"  />
                      <div class="text-danger" v-if="!$v.coverDetails.fullName.required && $v.coverDetails.fullName.$dirty">Full name is required</div>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-12">
                      <label :class="{ 'text-danger': $v.coverDetails.phoneNumber.$error }">Phone Number:<sup>*</sup> </label> <br/>
                      <input v-model.trim="$v.coverDetails.phoneNumber.$model" type="text" class="form-control"  />

                      <div class="text-danger" v-if="!$v.coverDetails.phoneNumber.required && $v.coverDetails.phoneNumber.$dirty">Phone number is required</div>
                      <div class="text-danger" v-if="!$v.coverDetails.phoneNumber.numeric && $v.coverDetails.phoneNumber.$dirty">Phone number should be numeric</div>
                      <div class="text-danger" v-if="!$v.coverDetails.phoneNumber.minLength && $v.coverDetails.phoneNumber.$dirty">Phone number minimum length should be 10</div>
                      <div class="text-danger" v-if="!$v.coverDetails.phoneNumber.maxLength && $v.coverDetails.phoneNumber.$dirty">Phone number maximum length should be 10</div>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-12">
                      <label :class="{ 'text-danger': $v.coverDetails.emailAddress.$error }">Email Address: <sup>*</sup> </label> <br/>
                      <input type="text"  v-model.trim="$v.coverDetails.emailAddress.$model" class="form-control" />
                      <div class="text-danger" v-if="!$v.coverDetails.emailAddress.required && $v.coverDetails.emailAddress.$dirty">Email address is required</div>
                      <div class="text-danger" v-if="!$v.coverDetails.emailAddress.email && $v.coverDetails.emailAddress.$dirty">Not a valid email address</div>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-12">
                      <label :class="{ 'text-danger': $v.coverDetails.identificationNumber.$error }">ID No. / Passport No. : <sup>*</sup> </label> <br/>
                      <input type="text" v-model.trim="$v.coverDetails.identificationNumber.$model" class="form-control" v-model="coverDetails.identificationNumber" />

                      <div class="text-danger" v-if="!$v.coverDetails.identificationNumber.required && $v.coverDetails.identificationNumber.$dirty">ID No. / Passport No is required</div>
                      <div class="text-danger" v-if="!$v.coverDetails.identificationNumber.minLength && $v.coverDetails.identificationNumber.$dirty">ID No. / Passport minimum length should be 6</div>
                      <div class="text-danger" v-if="!$v.coverDetails.identificationNumber.maxLength && $v.coverDetails.identificationNumber.$dirty">ID No. / Passport maximum length should be 10</div>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-12">
                      <label :class="{ 'text-danger': $v.coverDetails.kraPin.$error }">KRA Pin : <sup>*</sup> </label> <br/>
                      <input v-model.trim="$v.coverDetails.kraPin.$model" type="text" class="form-control" />
                      <div class="text-danger" v-if="!$v.coverDetails.kraPin.required && $v.coverDetails.kraPin.$dirty">KRA Pin is required</div>
                      <div class="text-danger" v-if="!$v.coverDetails.kraPin.minLength && $v.coverDetails.kraPin.$dirty">KRA Pin minimum length should be 8</div>
                      <div class="text-danger" v-if="!$v.coverDetails.kraPin.maxLength && $v.coverDetails.kraPin.$dirty">KRA Pin maximum length should be 10</div>
                      <div class="text-danger" v-if="!$v.coverDetails.kraPin.alphaNum && $v.coverDetails.kraPin.$dirty">KRA Pin  should be alpha numeric</div>

                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-12">
                      <label :class="{ 'text-danger': $v.coverDetails.gender.$error }" >Gender: <sup>*</sup> </label> <br/>
                      <select class="form-control"  v-model.trim="$v.coverDetails.gender.$model">
                        <option value=""> -- Select -- </option>
                        <option value="male"> Male</option>
                        <option value="female"> Female</option>
                      </select>
                      <div class="text-danger" v-if="!$v.coverDetails.gender.required && $v.coverDetails.gender.$dirty">Gender is required</div>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-12">
                      <h6 style="margin-bottom: 5px;">Preferred policy starting date: </h6>
                      <label :class="{ 'text-danger': $v.coverDetails.preferredStartDate.$error }">Starting Date: </label> <br/>
                      <date-picker :disabled-date="disabledDatesFrom" v-model.trim="$v.coverDetails.preferredStartDate.$model" style="width: 100%;"  valueType="format"></date-picker>
                      <div class="text-danger" v-if="!$v.coverDetails.preferredStartDate.required && $v.coverDetails.preferredStartDate.$dirty">Preferred policy starting date is required</div>
                    </div>
                  </div>
                </div>


              </div>
            </tab-content>

            <tab-content title="Upload documents" :before-change="uploadUserDocuments"
                         icon="ti-user"  >

              <div class=" shadow-lg bg-white">

                <div class="mb-3 p-3" v-if="upload_documents_error_message">
                  <div class="alert alert-danger">
                    {{ upload_documents_error_message }}
                  </div>
                </div>

                <div class="p-3">
                  <div class="row">
                    <div class="col-12">
                      <label  >Upload your identification card / Passport(Front Side): <sup style="font-size:10px;" class="text-danger">*</sup>  </label> <br/>
                      <div class="card card-bordered p-5">
                        <input  type="file" class="form-control" ref="identification_front_side"  @change="handleIdentificationFrontSideUpload" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <label >Upload your identification card / Passport(Back Side):<sup class="text-danger">*</sup> </label> <br/>
                      <div class="card card-bordered p-5">
                        <input  type="file" class="form-control" ref="identification_back_side" @change="handleIdentificationBackSideUpload"  />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <label >Upload your KRA pin: <sup class="text-danger">*</sup>  </label> <br/>
                      <div class="card card-bordered p-5">
                        <input  type="file" class="form-control" ref="kra_document" @change="handleKRADocumentUpload"  />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <label >Upload your logbook:<sup class="text-danger">*</sup> </label> <br/>
                      <div class="card card-bordered p-5">
                        <input  type="file" class="form-control" ref="logbook" @change="handleLogbookUpload"   />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </tab-content>

            <tab-content title="Device Installation" :before-change="scheduleInstallation"
                         icon="ti-user">

              <div class="shadow-lg bg-white">
                <div class="p-3">
                  <div class="row">
                    <div class="col-12">

                      <h5>Schedule For device installation and valuation</h5>

                      <div class="mb-3">
                        <label :class="{ 'text-danger': $v.coverDetails.scheduleDetails.dateOfInstallation.$error }">Date: </label> <br/>
                        <date-picker :disabled-date="disabledDatesFrom"  v-model.trim="$v.coverDetails.scheduleDetails.dateOfInstallation.$model" style="width: 100%;"  valueType="format"></date-picker>
                        <div class="text-danger" v-if="!$v.coverDetails.scheduleDetails.dateOfInstallation.required && $v.coverDetails.scheduleDetails.dateOfInstallation.$dirty">Date of installation is required</div>
                      </div>

                      <div class=" mb-3">
                        <label :class="{ 'text-danger': $v.coverDetails.scheduleDetails.timeOfInstallation.$error }" >Time: </label> <br/>
                        <date-picker v-model.trim="$v.coverDetails.scheduleDetails.timeOfInstallation.$model" type="time" style="width: 100%;" valueType="format"></date-picker>
                        <div class="text-danger" v-if="!$v.coverDetails.scheduleDetails.timeOfInstallation.required && $v.coverDetails.scheduleDetails.timeOfInstallation.$dirty">Time of installation is required</div>
                      </div>

                      <div class=" mb-3">
                        <label class="control-label">Location</label> <br/>
                        <gmap-autocomplete
                            :value="coverDetails.locationOfInstallation"

                            @place_changed="setPlace"
                            class="form-control"
                        >
                        </gmap-autocomplete>

                        <small class="form-text text-muted">(Start typing to search location)</small>
                      </div>
                      <div class=" mb-3" style="color:#0077BE;">
                        One of our valuation agents will get into contact with you to facilitate the process*
                      </div>
                      <!--                      <div class="row">

                                              <div class="col-md-12">
                                                <gmap-map @click="clicked" :center="center" :zoom="9" style="width: 100%; height: 400px">
                                                  <gmap-marker :position="markers.position" :clickable="true" :draggable="false" @click="center=markers.position"></gmap-marker>
                                                </gmap-map>
                                              </div>
                                            </div>-->
                    </div>

                  </div>
                </div>
              </div>

            </tab-content>

          </form-wizard>

        </div>
      </div>
    </div>

    <modal name="success-checkout" height="550">
      <div class="p-5 text-center success-checkout">
        <img src="@/client/assets/images/success_check.png">

        <div class="mb-5">
          <h4 class="text-success">Your payment was successful</h4>
        </div>


        <div class="mb-5">
          If you’re a <strong>new User</strong> Kindly check your <strong>email inbox <br/>
          or spam folder </strong> for further instructions from AICARE.
        </div>

        <div style="border: 1px solid #6F6F6F;border-radius: 6px;" class="p-3">

          <div class="mt-4 mb-4">
            If you already have an account
          </div>

          <RouterLink style="width: 100%;font-weight: bolder;" to="/login" class="btn  btn-secondary btn-block quote-btn" > Login </RouterLink>
        </div>
      </div>
    </modal>


  </div>
</template>

<script>

import {FormWizard, TabContent} from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {alphaNum, email, maxLength, minLength, numeric, required, requiredIf} from 'vuelidate/lib/validators'

import carBrands from "./car_brands";

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { mapActions } from "vuex";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: 'home',
  data () {
    return {
      place_desc:"",
      isLoading: false,
      fullPage: true,
      scheduleInstallationActive: false,
      termsAndConditionsActive: false,
      coverDetails:{
        quoteId:"",
        preferredStartDate:"",
        kraPin:"",
        postalCode:"",
        poBoxNumber:"",
        vehicleReg:"",
        fullName:"",
        phoneNumber:"",
        idType:"",
        identificationNumber:"",
        emailAddress:"",
        gender:"",
        termsAndCondition:"",
        locationOfInstallation:"",
        scheduleDetails:{
          dateOfInstallation:"",
          timeOfInstallation:""
        }
      },
      car_brands:carBrands,
      markers: {
        position: { lat: -1.292066, lng: 36.821946  }
      },
      center: { lat: -1.292066, lng: 36.821946 },
      identification_front_side_file:"",
      identification_back_side_file:"",
      kra_document_file:"",
      logbook_file:"",
      upload_documents_error_message:"",

    };
  },
  validations: {
    coverDetails:{
      preferredStartDate:{required},
      kraPin:{
        required,
        alphaNum,
        minLength: minLength(11),
        maxLength: maxLength(11)
      },
      fullName:{required},
      phoneNumber:{
        required,
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(10)
      },
      emailAddress:{
        required,
        email
      },
      gender:{required},
      identificationNumber:{
        required,
        minLength: minLength(6),
        maxLength: maxLength(10)
      },
      termsAndCondition:{
        required: requiredIf(function (cover) {
          return this.isOptionalTermsAndConditionsActive
        })
      },
      scheduleDetails:{
        dateOfInstallation:{
          required: requiredIf(function (cover) {
            return this.isOptionalScheduleInstallationActive
          })
        },
        timeOfInstallation:{
          required: requiredIf(function (cover) {
            return this.isOptionalScheduleInstallationActive
          })
        }
      }
    }
  },
  computed: {
    isOptionalScheduleInstallationActive() {
      return  this.scheduleInstallationActive
    },
    isOptionalTermsAndConditionsActive(){
      return this.termsAndConditionsActive;
    }
  },
  components: {
    FormWizard,
    DatePicker,
    TabContent,
    Loading
  },

  mounted(){
    this.getQuoteData();
  },
  methods:{
    ...mapActions(["getQuoteDetails","updatePersonalQuoteInformation","updateValuation","finalizeQuoteDetails","uploadDocuments"]),

    disabledDatesFrom(date){
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return today > date ;
    },
    handleIdentificationFrontSideUpload(){
      this.identification_front_side_file = this.$refs.identification_front_side.files[0];
    },
    handleIdentificationBackSideUpload(){
      this.identification_back_side_file = this.$refs.identification_back_side.files[0];
    },
    handleKRADocumentUpload(){
      this.kra_document_file = this.$refs.kra_document.files[0];
    },
    handleLogbookUpload(){
      this.logbook_file = this.$refs.logbook.files[0];
    },

    setPlace(place) {

      this.coverDetails.locationOfInstallation = place.name;


      this.markers.position.lat = place.geometry.location.lat();
      this.markers.position.lng = place.geometry.location.lng();

      this.center = this.markers.position;

    },

    clicked(e) {


      this.markers.position.lat = e.latLng.lat();
      this.markers.position.lng = e.latLng.lng();

      this.center = this.markers.position;
    },

    getQuoteData(){
      let self = this;
      this.isLoading = true;

      this.getQuoteDetails({id: this.$route.params.quoteId}).then((quote) => {
        self.isLoading = false;

        this.coverDetails.fullName = quote.fullName;
        this.coverDetails.phoneNumber = quote.phoneNumber;
        this.coverDetails.emailAddress = quote.emailAddress;
        this.coverDetails.identificationNumber = quote.identificationNumber;
        this.coverDetails.postalCode = quote.postalCode;
        this.coverDetails.poBoxNumber = quote.poBoxNumber;
        this.coverDetails.kraPin = quote.kraPin;
        this.coverDetails.gender = quote.gender;
        this.coverDetails.preferredStartDate = quote.preferredStartDate;
        this.coverDetails.quoteId = quote._id;


      }).catch(error => {
        self.isLoading = false;
        console.log(error);
      })
    },

    updatePersonalDetails() {
      console.log("Submit step 1")
      let self = this;
      return new Promise((resolve, reject) => {
        self.$v.$touch();
        if (!self.$v.$invalid) {
          self.isLoading = true;

          let quoteData = {
            fullName:this.coverDetails.fullName,
            phoneNumber:this.coverDetails.phoneNumber,
            emailAddress:this.coverDetails.emailAddress,
            config:"switch",
            identificationNumber: this.coverDetails.identificationNumber,
            postalCode:"N/A",
            poBoxNumber: "N/A",
            kraPin:this.coverDetails.kraPin,
            gender:this.coverDetails.gender,
            preferredStartDate:this.coverDetails.preferredStartDate
          };

          self.updatePersonalQuoteInformation({quoteId: this.coverDetails.quoteId, quoteData: quoteData}).then((quote) => {

            console.log("SUCCESS STEP 1");
            self.isLoading = false;

            resolve(true);



          }).catch(error => {
            console.log(error);
            self.isLoading = false;

            return false;


          })
        }
      })
    },
    uploadUserDocuments() {

      let self = this;

      if(!this.identification_front_side_file){
        this.upload_documents_error_message = "Identification / passport front side is required";
      }
      else if(!this.identification_back_side_file){
        this.upload_documents_error_message = "Identification / passport back side is required";
      }
      else if(!this.kra_document_file){
        this.upload_documents_error_message = "Tax document is required";
      }
      else if(!this.logbook_file){
        this.upload_documents_error_message = "Log book is required";
      }
      else{
        this.upload_documents_error_message = "";
        return new Promise((resolve, reject) => {

          let formdata = new FormData();


          formdata.append("identification_document_front", this.identification_front_side_file);
          formdata.append("identification_document_back", this.identification_back_side_file);
          formdata.append("kra_document", this.kra_document_file);
          formdata.append("log_book", this.logbook_file);

          self.isLoading = true;

          let quoteData = {
            documents:formdata,
            quoteId: this.coverDetails.quoteId
          };

          self.uploadDocuments(quoteData).then(() => {
            self.isLoading = false;

            resolve(true);

            self.scheduleInstallationActive = true;

          }).catch(error => {
            console.log(error);
            self.isLoading = false;

            return false;


          })
        });
      }
    },
    scheduleInstallation(){
      let self = this;
      return new Promise((resolve, reject) => {

        self.$v.$touch();
        if (!self.$v.$invalid) {
          self.isLoading = true;

          let quoteData = {
            date:this.coverDetails.scheduleDetails.dateOfInstallation,
            time:this.coverDetails.scheduleDetails.timeOfInstallation,
            location: this.markers.position.lat+", "+this.markers.position.lng
          };

          localStorage.setItem("scheduleDate", this.coverDetails.scheduleDetails.dateOfInstallation);
          localStorage.setItem("scheduleTime", this.coverDetails.scheduleDetails.timeOfInstallation);
          localStorage.setItem("scheduleLocation", this.coverDetails.locationOfInstallation)

          self.updateValuation({quoteId: this.coverDetails.quoteId, quoteData: quoteData}).then((quote) => {
            self.isLoading = false;
            location.replace("/cover-summary/"+self.coverDetails.quoteId);

          }).catch(error => {
            console.log(error);
            self.isLoading = false;

            return false;


          })
        }
        else{
          console.log("Validating schedule");
        }


      })
    },

    updateUserInformation(){
      let self = this;

      this.isLoading = true;

      let quoteData = {
        fullName:this.coverDetails.fullName,
        phoneNumber:this.coverDetails.phoneNumber,
        emailAddress:this.coverDetails.emailAddress,
        config:"switch"
      };

      this.updatePersonalQuoteInformation({quoteId: this.coverDetails.quoteId, quoteData: quoteData}).then((response) => {
        self.isLoading = false;

        console.log(response)
      }).catch(error => {
        self.isLoading = false;
        console.log(error);
      })
    },

    /*updateQuoteData(){

        console.log("Before change called");

        let self = this;

        this.isLoading = true;

        let quoteData = {
            fullName:this.coverDetails.fullName,
            phoneNumber:this.coverDetails.phoneNumber,
            emailAddress:this.coverDetails.emailAddress,
            config:"switch",
            identificationNumber: this.coverDetails.identificationNumber,
            postalCode:this.coverDetails.postalCode,
            kraPin:this.coverDetails.kraPin,
            gender:this.coverDetails.gender,
            preferredStartDate:this.coverDetails.preferredStartDate
        };

        this.updatePersonalQuoteInformation({quoteId: this.coverDetails.quoteId, quoteData: quoteData}).then((quote) => {

            console.log(quote);
            self.isLoading = false;

            self.$refs.personalDetails.nextTab()



        }).catch(error => {
            console.log(error);
            self.isLoading = false;

            return false;


        })


    },*/

    nextStep(e){
      console.log("Next step");
      console.log(e);
    },
    previousStep(e){
      console.log("Previous step");
      console.log(e);
    },
    validateBeforeSubmit(){

      console.log("Clicked")
      console.log(this.coverDetails.termsAndCondition);
      if(this.coverDetails.termsAndCondition){
        let self = this;

        return new Promise((resolve, reject) => {

          self.isLoading = true;

          console.log(self.coverDetails.quoteId);

          self.finalizeQuoteDetails({quoteId: self.coverDetails.quoteId}).then((response) => {
            self.isLoading = false;

            resolve(true);

            self.$modal.show('success-checkout');

          }).catch(error => {
            self.isLoading = false;
            console.log(error);
          })
        });
      }


    }
  }
}
</script>

<style >

.vue-form-wizard .wizard-icon-circle .wizard-icon-container{
  background-color: transparent !important;
}
.vue-form-wizard.sm .wizard-nav-pills>li.active>a .stepTitle{
  color:#0077BE !important;
}


.vue-form-wizard.sm .wizard-nav-pills>li.active>a .wizard-icon{
  height: 30px !important;
  width: 30px !important;
  margin-top: -13px;
  background-repeat: no-repeat;

}

.vue-form-wizard .wizard-tab-content {
  padding-top: 50px;
}
.stepTitle{
  margin-top: -50px;
  color:#6F6F6F;
  font-weight: 600;
  font-size: 12px;
}
.vue-form-wizard.sm .wizard-icon-circle{
  height: 10px;
  width: 10px;
  background-color: #C1C1C1;
  border: none;
}
.vue-form-wizard.sm .wizard-navigation .wizard-progress-with-circle{
  top:9px;
  left:12.5%;
  width: 75.5% !important;
  background-color: #C1C1C1 !important;
  height: 2px !important;
}
table tr th{
  padding-top: 10px;
  padding-bottom: 10px;
}
fieldset.custom-border {
  border: 1px groove #ddd !important;
  padding: 0 1.4em 1.4em 1.4em !important;
  margin: 0 0 1.5em 0 !important;
  -webkit-box-shadow: 0px 0px 0px 0px #000;
  box-shadow: 0px 0px 0px 0px #000;
}

legend.custom-border {
  width: auto;
  padding: 0 10px;
  border-bottom: none;
}
.vue-step-wizard{
  width: 100% !important;
}
.tabStatus{
  background-color: #002563 !important;
}
.tabLabel{
  font-weight: bolder;
}

label{
  font-size: 12px;
}

.vue-form-wizard .wizard-navigation .wizard-progress-with-circle .wizard-progress-bar{
  width: 0% !important;
}
.vue-form-wizard .navbar .navbar-nav>li>a.wizard-btn, .vue-form-wizard .wizard-btn{
  background-color: #E42312 !important;
  color:#fff !important;
  border: none;
  margin-top: 20px;

}

table td{
  padding: 10px;
}
</style>